import React from 'react';
import { Box, Typography, Grid, Tooltip, MenuItem, ListItemIcon } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import '../index.css';

const Footer = () => {
  return (
    <Box 
      component="footer" 
      sx={{ 
        backgroundColor: '#ffffff',
        color: '#646464',
        py: 2,
        width: '100%',
        borderTop: '1px solid #9a9891'
      }}
    >
      <Box sx={{ maxWidth: '1200px', mx: 'auto', px: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom sx={{ color: '#646464', mb: 1 }}>
              Contact Us
            </Typography>
            <Typography variant="body" sx={{ color: '#646464' }}>
              📩 Email: pragmatic.pyramid@gmail.com
            </Typography>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom sx={{ color: '#646464', mb: 1 }}>
              Connect with Us
            </Typography>
            <Tooltip 
              title="Partner with us to showcase your motorcycle tours and reach adventure-seeking riders worldwide" 
              arrow
              placement="right"
            >
              <MenuItem 
                component="a"
                href="/adventure-packages"
                sx={{ 
                  color: '#646464',
                  display: 'block', 
                  mb: 0.5,
                  py: 0.5,
                  '&:hover': {
                    color: '#7f7e7a',
                    textDecoration: 'none'
                  }
                }}
              >
                🤝 Partner With Us!
              </MenuItem>
            </Tooltip>
            
            <Tooltip 
              title="Share your motorcycle journey, tips, and experiences with our community of riders" 
              arrow
              placement="right"
            >
              <MenuItem 
                component="a"
                href="/riders-tales"
                sx={{ 
                  color: '#646464',
                  display: 'block', 
                  mb: 0.5,
                  py: 0.5,
                  '&:hover': {
                    color: '#7f7e7a',
                    textDecoration: 'none'
                  }
                }}
              >
                📖 Submit Your Story
              </MenuItem>
            </Tooltip>
            
            <Tooltip 
              title="Reach our engaged audience of motorcycle enthusiasts with targeted advertising solutions" 
              arrow
              placement="right"
            >
              <MenuItem 
                component="a"
                href="/advertise"
                sx={{ 
                  color: '#646464',
                  display: 'block', 
                  mb: 0.5,
                  py: 0.5,
                  '&:hover': {
                    color: '#7f7e7a',
                    textDecoration: 'none'
                  }
                }}
              >
                📢 Advertise With Us
              </MenuItem>
            </Tooltip>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom sx={{ color: '#646464', mb: 1 }}>
              Pricing
            </Typography>
            
            <MenuItem 
              component="a"
              href="/pricing-riders"
              sx={{ 
                color: '#646464',
                display: 'block', 
                mb: 0.5,
                py: 0.5,
                '&:hover': {
                  color: '#7f7e7a',
                  textDecoration: 'none'
                }
              }}
            >
              🌍 Tour Operators
            </MenuItem>

            <MenuItem 
              component="a"
              href="/pricing-operators"
              sx={{ 
                color: '#646464',
                display: 'block', 
                mb: 0.5,
                py: 0.5,
                '&:hover': {
                  color: '#7f7e7a',
                  textDecoration: 'none'
                }
              }}
            >
              🏍️ Riders
            </MenuItem>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom sx={{ color: '#646464'}}>
              Follow Us
            </Typography>
            <MenuItem 
              component="a"
              href="https://www.instagram.com/travelriders.market"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ 
                color: '#646464',
                display: 'flex', 
                alignItems: 'center',
                gap: 0.5,
                py: 0.5,
                mb: 2,
                '&:hover': {
                  color: '#7f7e7a',
                  textDecoration: 'none'
                },

              }}
            >
              <ListItemIcon sx={{ minWidth: 36 }}>
                <InstagramIcon className="whiteIcon" />
              </ListItemIcon>
              <Typography variant="body">@travelriders.market</Typography>
            </MenuItem>
            <Typography variant="body" sx={{ color: '#646464'}}>
              © {new Date().getFullYear()} Travel-Riders.com. All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer; 