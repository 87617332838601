import React, { useState } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { LoadPreferences, UpdatePreferences } from '../../auth/auth0-provider-with-history';
import { Checkbox } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Preferences() {
    const [routeGenerationPreferences, setRouteGenerationPreferences] = useState(LoadPreferences());  // Load initial preferences
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handlePreferenceChange = (event) => {
        const { name } = event.target;

        // Update the local preferences state first
        setRouteGenerationPreferences((prevData) => {
            const updatedPreferences = {
                ...prevData,
                [name]: !prevData[name],  // Toggle the current preference
            };

            // Call UpdatePreferences to save the changes
            UpdatePreferences(updatedPreferences);

            return updatedPreferences;
        });
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h4>Route Generation Parameters:</h4>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox name="AVOIDHIGHWAYS" checked={routeGenerationPreferences.AVOIDHIGHWAYS} onChange={handlePreferenceChange} />}
                            label="Avoid Highways"
                        />
                        <FormControlLabel
                            control={<Checkbox name="AVOIDTOLLROADS" checked={routeGenerationPreferences.AVOIDTOLLROADS} onChange={handlePreferenceChange} />}
                            label="Avoid Toll Roads"
                        />
                        <FormControlLabel
                            control={<Checkbox name="AVOIDFERRIES" checked={routeGenerationPreferences.AVOIDFERRIES} onChange={handlePreferenceChange} />}
                            label="Avoid Ferries"
                        />
                    </FormGroup>
                </Box>
            </Modal>
        </div>
    );
}
