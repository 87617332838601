import React from "react";
import TopMenu from "./components/Menus/TopMenu";
import LeftPage from "./components/Pages/LeftPage";
import MapPage from "./components/Pages/MapPage";
import AdventurePackagesPage from "./components/Pages/AdventurePackagesPage";
import RidersTalesPage from "./components/Pages/RidersTalesPage";
import RidersGuidePage from "./components/Pages/RidersGuidePage";
import Footer from "./components/Footer";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './index.css';
import usePersistentState from "./auth/usePersistentState";
import { Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { LoadScript } from '@react-google-maps/api';
import AdvertisePage from './components/Pages/AdvertisePage';

const libraries = ['places'];

const theme = createTheme({
  palette: {
    primary: {
      main: '#646464',
      light: '#7f7e7a',
      dark: '#4a4a4a',
    },
    secondary: {
      main: '#9a9891',
      light: '#b5b3a8',
      dark: '#7f7e7a',
    },
    background: {
      default: '#ffffff',
      paper: '#f5f5f5',
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        "root": {
          "&.Mui-checked": {
            "color": "#646464"
          }
        }
      },
    },
  },
});

const App = () => {
  const [waypoints, setWaypoints] = usePersistentState('waypoints',[])
  const [routes, setRoutes] = usePersistentState('routes',null);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          height: '100vh',
          position: 'relative'
        }}>
          <TopMenu />
          <div style={{ 
            display: 'flex', 
            flex: 1,
            overflow: 'auto',
            position: 'relative'
          }}>
            <Routes>
              <Route path="/" element={
                <>
                  <LeftPage waypoints={waypoints} setWaypoints={setWaypoints} routes={routes} setRoutes={setRoutes} />
                  <div style={{ flex: 1 }}>
                    <MapPage waypoints={waypoints} setWaypoints={setWaypoints} routes={routes} setRoutes={setRoutes} />
                  </div>
                </>
              } />
              <Route path="/adventure-packages" element={<AdventurePackagesPage />} />
              <Route path="/riders-tales" element={<RidersTalesPage />} />
              <Route path="/riders-guide" element={<RidersGuidePage />} />
              <Route path="/advertise" element={<AdvertisePage />} />
            </Routes>
          </div>
          <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }}>
            <Footer />
          </div>
        </div>
      </LoadScript>
    </ThemeProvider>
  );
};

export default App;