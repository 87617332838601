import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Grid,
  Paper,
  Alert,
  ListItemIcon
} from '@mui/material';
import '../../index.css';

const AdvertisePage = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    
    try {
      const response = await fetch('/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData).toString(),
      });

      if (response.ok) {
        setSubmitted(true);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <Box sx={{ 
      p: 3,
      maxWidth: '800px',
      mx: 'auto',
      minHeight: 'calc(100vh - 64px - 200px)', // Account for header and footer
      backgroundColor: '#ffffff' // Added background color to match Adventure Packages form
    }}>
      <Paper elevation={3} sx={{ p: 4, backgroundColor: '#ffffff' }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#646464', mb: 3 }}>
          Advertise with Travel-Riders
        </Typography>
        
        <Alert severity="info" sx={{ mb: 3, backgroundColor: '#f5f5f5', color: '#646464' }}>
          Reach thousands of motorcycle enthusiasts and adventure seekers. Whether you're a tour operator, 
          motorcycle brand, or travel gear manufacturer, we offer targeted advertising solutions to connect 
          with our engaged audience.
        </Alert>

        {submitted ? (
          <Alert severity="success" sx={{ mt: 2 }}>
            Thank you for your interest! We'll get back to you shortly with more information about our advertising opportunities.
          </Alert>
        ) : (
          <form 
            name="advertise"
            method="POST"
            data-netlify="true"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="advertise" />
            <input type="hidden" name="bot-field" />
            
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <MenuItem 
                  component="div"
                  sx={{ 
                    display: 'block',
                    width: '100%',
                    mb: 1
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    label="Name"
                    name="name"
                    sx={{ 
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#7f7e7a',
                        },
                      },
                    }}
                  />
                </MenuItem>
              </Grid>

              <Grid item xs={12} sm={6}>
                <MenuItem 
                  component="div"
                  sx={{ 
                    display: 'block',
                    width: '100%',
                    mb: 1
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    sx={{ 
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#7f7e7a',
                        },
                      },
                    }}
                  />
                </MenuItem>
              </Grid>

              <Grid item xs={12}>
                <MenuItem 
                  component="div"
                  sx={{ 
                    display: 'block',
                    width: '100%',
                    mb: 1
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    label="Company Name"
                    name="company"
                    sx={{ 
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#7f7e7a',
                        },
                      },
                    }}
                  />
                </MenuItem>
              </Grid>

              <Grid item xs={12} sm={6}>
                <MenuItem 
                  component="div"
                  sx={{ 
                    display: 'block',
                    width: '100%',
                    mb: 1
                  }}
                >
                  <FormControl fullWidth required>
                    <InputLabel>Advertiser Type</InputLabel>
                    <Select
                      name="type"
                      label="Advertiser Type"
                      sx={{ 
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#7f7e7a',
                        },
                      }}
                    >
                      <MenuItem value="tour_operator">Tour Operator</MenuItem>
                      <MenuItem value="motorcycle_brand">Motorcycle Brand</MenuItem>
                      <MenuItem value="gear_manufacturer">Gear Manufacturer</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </MenuItem>
              </Grid>

              <Grid item xs={12} sm={6}>
                <MenuItem 
                  component="div"
                  sx={{ 
                    display: 'block',
                    width: '100%',
                    mb: 1
                  }}
                >
                  <FormControl fullWidth required>
                    <InputLabel>Monthly Budget Range</InputLabel>
                    <Select
                      name="budget"
                      label="Monthly Budget Range"
                      sx={{ 
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#7f7e7a',
                        },
                      }}
                    >
                      <MenuItem value="500-1000">€500 - €1,000</MenuItem>
                      <MenuItem value="1000-2000">€1,000 - €2,000</MenuItem>
                      <MenuItem value="2000-5000">€2,000 - €5,000</MenuItem>
                      <MenuItem value="5000+">€5,000+</MenuItem>
                    </Select>
                  </FormControl>
                </MenuItem>
              </Grid>

              <Grid item xs={12}>
                <MenuItem 
                  component="div"
                  sx={{ 
                    display: 'block',
                    width: '100%',
                    mb: 1
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    multiline
                    rows={4}
                    label="Tell us about your advertising goals"
                    name="message"
                    sx={{ 
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#7f7e7a',
                        },
                      },
                    }}
                  />
                </MenuItem>
              </Grid>

              <Grid item xs={12}>
                <MenuItem 
                  component="div"
                  sx={{ 
                    display: 'block',
                    width: '100%',
                    mb: 1
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                      backgroundColor: '#7f7e7a',
                      '&:hover': {
                        backgroundColor: '#9a9891',
                      },
                    }}
                  >
                    Submit Inquiry
                  </Button>
                </MenuItem>
              </Grid>
            </Grid>
          </form>
        )}
      </Paper>
    </Box>
  );
};

export default AdvertisePage; 