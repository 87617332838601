import React, { useState } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    IconButton,
    ListItemSecondaryAction,
    TextField
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit'; // Import the edit icon

import './../index.css';


const WaypointsList = ({ waypoints, setWaypoints, routes, setRoutes }) => {
    const [editingIndex, setEditingIndex] = useState(null);
    const [editedName, setEditedName] = useState('');


    const handleDelete = (index) => {
        if (!waypoints) return;
        const items = Array.from(waypoints);
        items.splice(index, 1);
        setWaypoints(items);
        ///delete routes
        setRoutes(null);    

    };

    const handleEdit = (index) => {
        setEditingIndex(index);
        setEditedName(waypoints[index].name || `Waypoint ${index + 1}`);
    };

    const handleRename = (index) => {
        const items = Array.from(waypoints);
        items[index].name = editedName;
        setWaypoints(items);
        setEditingIndex(null);
    };

    const handleRenameChange = (event,index) => {
        setEditedName(event.target.value || `Waypoint ${index + 1}`);
    };

    const handleRenameKeyPress = (event, index) => {
        if (event.key === 'Enter') {
            handleRename(index);
        }
    };

    return (
        <List>
            {
                waypoints.map((waypoint, index) =>
                (
                    <ListItem key={index}>
                        {editingIndex === index ? (
                        <TextField
                            value={editedName}
                            onChange={(event) => handleRenameChange(event, index)}
                            onBlur={() => handleRename(index)}
                            onKeyPress={(event) => handleRenameKeyPress(event, index)}
                            autoFocus
                        />
                    ) : (
                        <>
                            <ListItemText
                                primary={waypoint.name}
                                onClick={() => handleEdit(index)}
                            />
                        </>
                    )}
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(index)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
        </List >

    );
};

export default WaypointsList;