import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ListItemIcon, MenuItem } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import ProfileMenu from "./ProfileMenu";
import '../index.css';

const LoginLogoutButton = () => {
    const { isAuthenticated, loginWithRedirect} = useAuth0();


    const signupUser = async () => {
        await loginWithRedirect({
          screen_hint: 'signup',  // Hint to show the signup screen
        });
      };

    if (isAuthenticated)
        return (
            <ProfileMenu />
        )
    else
        return (
            <MenuItem className="menu-item" component="a" onClick={signupUser} target="_blank">
                <ListItemIcon>
                    <LoginIcon className="whiteIcon" />
                </ListItemIcon>
                Login
            </MenuItem>)
};

export default LoginLogoutButton;