import React, { useState } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  Alert,
  TextField,
  Button,
  Grid,
  ListItemIcon
} from '@mui/material';
import '../../index.css';

const AdventurePackagesPage = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    
    try {
      const response = await fetch('/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData).toString(),
      });

      if (response.ok) {
        setSubmitted(true);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <Box sx={{ 
      p: 3,
      maxWidth: '800px',
      mx: 'auto',
      minHeight: 'calc(100vh - 64px - 200px)',
      backgroundColor: '#ffffff'
    }}>
      <Paper elevation={3} sx={{ p: 4, backgroundColor: '#ffffff' }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#646464', mb: 3 }}>
          Let's get you connected
        </Typography>
        
        <Alert severity="info" sx={{ mb: 3, backgroundColor: '#f5f5f5', color: '#646464' }}>
          Are you a motorcycle tour operator or adventure travel company? We'd love to hear from you 
          and discuss how we can showcase your packages to our community of riders.
        </Alert>

        {submitted ? (
          <Alert severity="success" sx={{ mt: 2 }}>
            Thank you for your message! We'll get back to you shortly to discuss your adventure packages.
          </Alert>
        ) : (
          <form 
            name="adventure-packages"
            method="POST"
            data-netlify="true"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="adventure-packages" />
            <input type="hidden" name="bot-field" />
            
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ListItemIcon 
                  component="div"
                  sx={{ 
                    display: 'block',
                    width: '100%',
                    mb: 1
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    label="Name"
                    name="name"
                    sx={{ 
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#7f7e7a',
                        },
                      },
                    }}
                  />
                </ListItemIcon>
              </Grid>

              <Grid item xs={12} sm={6}>
                <ListItemIcon 
                  component="div"
                  sx={{ 
                    display: 'block',
                    width: '100%',
                    mb: 1
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    sx={{ 
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#7f7e7a',
                        },
                      },
                    }}
                  />
                </ListItemIcon>
              </Grid>

              <Grid item xs={12}>
                <ListItemIcon 
                  component="div"
                  sx={{ 
                    display: 'block',
                    width: '100%',
                    mb: 1
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    label="Company"
                    name="company"
                    sx={{ 
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#7f7e7a',
                        },
                      },
                    }}
                  />
                </ListItemIcon>
              </Grid>

              <Grid item xs={12}>
                <ListItemIcon 
                  component="div"
                  sx={{ 
                    display: 'block',
                    width: '100%',
                    mb: 1
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    multiline
                    rows={6}
                    label="Message"
                    name="message"
                    sx={{ 
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#7f7e7a',
                        },
                      },
                    }}
                  />
                </ListItemIcon>
              </Grid>

              <Grid item xs={12}>
                <ListItemIcon 
                  component="div"
                  sx={{ 
                    display: 'block',
                    width: '100%',
                    mb: 1
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                      backgroundColor: '#7f7e7a',
                      '&:hover': {
                        backgroundColor: '#9a9891',
                      },
                    }}
                  >
                    Send Message
                  </Button>
                </ListItemIcon>
              </Grid>
            </Grid>
          </form>
        )}
      </Paper>
    </Box>
  );
};

export default AdventurePackagesPage; 