import axios from 'axios';

const generateRouteURL = '/.netlify/functions/generate-route';
//const generateRouteURL = 'http://localhost:8888/.netlify/functions/generate-route';

//const fs = require('fs');
//const util = require('util');
//const parseString = util.promisify(require('xml2js').parseString);
// Replace 'YOUR_API_KEY' with your Google Maps API key
//6 hours per day
//const maxLegTimeInSeconds = 21600;


/*

async function ConvertLegsToWaypoints(legs) {

    var waypoints=[];

    for(day = 0; day < legs.length ; day ++)
    {
        waypoints[0]=
        {
            "routeName": "Day" + (day+1),
            "waypoints" : 
            [
                {
                    "name" : "Day " + (day+1) + " Start"

                },
                {
                    "name" : "Day " + (day+1) + " End"
                }
            ]
        }

    }


    const orderedWaypoints = legs.map((leg, index) => {
        const { latitude, longitude } = leg.startLocation.latLng;

        if (index == 0) {
            return (
                {
                    "latitude": latitude,
                    "longitude": longitude,
                    "name": "StartPoint"
                })
        }
        return (
            {
                "latitude": latitude,
                "longitude": longitude,
                "name": "WPT" + index
            })
    });
    orderedWaypoints.push({
        "latitude": destination.latitude,
        "longitude": destination.longitude,
        "name": "Destination"
    })
    console.log(orderedWaypoints);
    return orderedWaypoints;
}




async function SplitLegsbyTime(legs, maxLegTimeInSeconds) {

    var daysAndSteps = [];
    var tempDayArray = [];
    var currentDayDuration = 0;
    //var day = 0;

    for (legIndex = 0; legIndex < legs.length; legIndex++) {
        for (stepIndex = 0; stepIndex < legs[legIndex].steps.length; stepIndex++) {
            stepDuration = removeLastCharAndConvertToInt(legs[legIndex].steps[stepIndex].staticDuration)

            if (currentDayDuration + stepDuration >= maxLegTimeInSeconds) {
                //daysAndSteps[day] = [];
                //daysAndSteps[day].push(tempDayArray);
                //day++;
                daysAndSteps.push(tempDayArray);
                currentDayDuration = 0;
                tempDayArray = [];
            }
            else {
                currentDayDuration += stepDuration;
                tempDayArray.push(legs[legIndex].steps[stepIndex]);
            }
        }
    }
    console.log(daysAndSteps);
    return daysAndSteps;
}


function removeLastCharAndConvertToInt(inputString) {
    // Check if the input string is not empty
    if (inputString.length > 0) {
        // Remove the last character
        const stringWithoutLastChar = inputString.substring(0, inputString.length - 1);

        // Convert the modified string to an integer
        const resultInt = parseInt(stringWithoutLastChar, 10);

        // Check if the conversion was successful
        if (!isNaN(resultInt)) {
            return resultInt;
        }
    }

    // Return a default value or handle the case where the conversion is not successful
    return 0; // Default value or another meaningful value
}


async function generateRoute(sourceFilePath, destFilePath) {
    try {

        var waypoints = await extractWaypointsFromGPXFile(sourceFilePath);
        var legs = await optimizeRoute(waypoints);

        //to be defined config
        if (true) {
            legs = await SplitLegsbyTime(legs, maxLegTimeInSeconds);
        }

        optimizedRoutes = convertToMultiTrackGPX(legs);


        saveXmlToFile(optimizedRoutes, destFilePath);
    } catch (error) {
        console.log(error);

    }

}

*/

export function convertWaypointsToGPXRoute(waypoints, routeName = 'Ride Route Planner Route') {
    const gpxHeader = `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>\n<gpx version="1.1" xmlns="http://www.topografix.com/GPX/1/1" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd">\n`;
    const gpxFooter = '</gpx>';
    const gpxRouteHeader = `<rte>\n  <name>${routeName}</name>\n`;
    const gpxRouteFooter = '</rte>\n';

    const gpxWaypoints = waypoints
        .map((waypoint, index) => {
            const { latitude, longitude, name, time } = waypoint;
            const gpxPoint = `  <rtept lat="${latitude}" lon="${longitude}">\n`;
            const gpxName = name ? `    <name>${name}</name>\n` : '';
            const gpxTime = time ? `    <time>${time.toISOString()}</time>\n` : '';
            const gpxPointEnd = '  </rtept>\n';
            return gpxPoint + gpxName + gpxTime + gpxPointEnd;
        })
        .join('');

    return gpxHeader + gpxRouteHeader + gpxWaypoints + gpxRouteFooter + gpxFooter;
}


export function extractOrderedWaypointsFromRoutes(routes) {
    const routesWithOrderedWaypoints = routes.map((route, routeIndex) => {
        const waypoints = [];
        route.legs.forEach((leg, legIndex) => {
            const { latitude: startLat, longitude: startLng } = leg.startLocation.latLng;
            const { latitude: endLat, longitude: endLng } = leg.endLocation.latLng;

            // Add the start waypoint
            if (legIndex === 0) {
                waypoints.push({
                    latitude: startLat,
                    longitude: startLng,
                    name: "Start"
                });
            }

            if (route.legs.length === 1) {
                waypoints.push({
                    latitude: endLat,
                    longitude: endLng,
                    name: "End"
                });
            }
            else {

                // For all legs except the last, add a waypoint at the start of the leg
                if (legIndex !== 0 && legIndex !== route.legs.length - 1) {
                    waypoints.push({
                        latitude: startLat,
                        longitude: startLng,
                        name: "WPT " + legIndex
                    });
                }

                // Add the end waypoint for the last leg
                if (legIndex === route.legs.length - 1) {
                    waypoints.push({
                        latitude: startLat,
                        longitude: startLng,
                        name: "WPT " + legIndex
                    });

                    waypoints.push({
                        latitude: endLat,
                        longitude: endLng,
                        name: "End"
                    });
                }
            }
        });
        return {
            routeName: "Route " + (routeIndex + 1),
            waypoints: waypoints
        };
    });

    return routesWithOrderedWaypoints;
}



export function convertToGPXRoute(waypoints, routeName = 'TravelRidersRoute') {
    const gpxHeader = `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>\n<gpx version="1.1" xmlns="http://www.topografix.com/GPX/1/1" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd">\n`;
    const gpxFooter = '</gpx>';
    const gpxRouteHeader = `<rte>\n  <name>${routeName}</name>\n`;
    const gpxRouteFooter = '</rte>\n';

    const gpxWaypoints = waypoints
        .map((waypoint, index) => {
            const { latitude, longitude, name, time } = waypoint;
            const gpxPoint = `  <rtept lat="${latitude}" lon="${longitude}">\n`;
            const gpxName = name ? `    <name>${name}</name>\n` : '';
            const gpxTime = time ? `    <time>${time.toISOString()}</time>\n` : '';
            const gpxPointEnd = '  </rtept>\n';
            return gpxPoint + gpxName + gpxTime + gpxPointEnd;
        })
        .join('');

    return gpxHeader + gpxRouteHeader + gpxWaypoints + gpxRouteFooter + gpxFooter;
}

export function extractWaypointsFromGPXFile(fileContent) {
    try {

        // Extract waypoints
        const waypoints = [];

        if (fileContent.gpx && fileContent.gpx.children) {
            fileContent.gpx.children.shift();
            const gpxWaypoints = fileContent.gpx.children;

            for (const gpxWaypoint of gpxWaypoints) {
                const waypoint = {
                    latitude: parseFloat(gpxWaypoint.wpt.lat),
                    longitude: parseFloat(gpxWaypoint.wpt.lon),
                    name: gpxWaypoint.wpt.children[0].name.content || undefined
                };
                waypoints.push(waypoint);
            }
        }
        return waypoints;
    } catch (error) {
        console.error('Error extracting waypoints from GPX file:', error.message);
        throw error;
    }
}


export async function GenerateRoute(waypoints, routeGenerationPreferences) {
    try {

        var legs = await GenerateOptimizedRoute(waypoints, routeGenerationPreferences);


        const route = {
            route: "Route",
            legs: legs
        }

        var routes = [];
        routes.push(route)

        return routes;

    } catch (error) {
        console.log(error);

    }

}

async function GenerateOptimizedRoute(waypoints, routeGenerationPreferences) {

    try {
        const response = await axios.post(generateRouteURL,
            {
                "origin": {
                    "location": {
                        "latLng": {
                            "latitude": waypoints[0].lat,
                            "longitude": waypoints[0].lng
                        }
                    }
                },
                "destination": {
                    "location": {
                        "latLng": {
                            "latitude": waypoints[waypoints.length - 1].lat,
                            "longitude": waypoints[waypoints.length - 1].lng
                        }
                    }
                },
                "intermediates": convertWaypointToGoogleFormat(waypoints.slice(1, waypoints.length - 1)),
                "travelMode": "DRIVE",
                "routingPreference": "TRAFFIC_UNAWARE",
                "computeAlternativeRoutes": false,
                "routeModifiers": {
                    "avoidTolls": routeGenerationPreferences["AVOIDTOLLROADS"],
                    "avoidHighways": routeGenerationPreferences["AVOIDHIGHWAYS"],
                    "avoidFerries": routeGenerationPreferences["AVOIDFERRIES"]
                },
                "languageCode": "en-US",
                "units": "METRIC",
                "optimizeWaypointOrder": routeGenerationPreferences["OPTIMIZEROUTE"],
                "polylineQuality": "HIGH_QUALITY",
                "polylineEncoding": "GEO_JSON_LINESTRING"
            }
        );

        // Handle the response data here
        const legs = response.data;
        return legs;

    } catch (error) {
        console.error('Error fetching directions:', error);
    }
}

function convertWaypointToGoogleFormat(waypoints) {
    return waypoints.map(waypoint => {
        return ({
            "location": {
                "latLng":
                {
                    "latitude": waypoint.lat,
                    "longitude": waypoint.lng
                }
            }

        })
    }
    )
}