import React from 'react';
import { Auth0Provider,useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const domain = "riderouteplanner.eu.auth0.com"

const Auth0ProviderWithHistory = ({ children }) => {
    const clientId = "V1aLImHclgZhqlNeNtq0CpLO76w1e3Yp";

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: window.location.origin
              }}
        >
            {children}
        </Auth0Provider>
    );
};

export const LoadPreferences = async() => {
    const { user, getAccessTokenSilently } = useAuth0();

        try {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                  audience: `https://${domain}/api/v2/`,
                  scope: "read:current_user update:current_user_metadata",
                },
              });

            console.log(accessToken)

            const response = await axios.get(
                `https://${domain}/api/v2/users/${user.sub}`,
                {
                    headers: {
                        authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            return({
                token: accessToken,
                metadata: response.data.user_metadata
            })
        } catch (e) {
            console.error(e);
        }
}

export const UpdatePreferences = async(preferences) => {
    const { user } = useAuth0();

    try{
        await axios.patch(`https://${domain}/api/v2/users/${user.sub}`, 
            { user_metadata: preferences.metadata },
            {
              headers: {
                Authorization: `Bearer ${preferences.accessToken}`,
              },
            }
          );
    } catch (e) {
        console.error(e)
    }
}

export default Auth0ProviderWithHistory;