import React, { useState, useEffect, useRef, useMemo } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap, Polyline, useMapEvent } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { StandaloneSearchBox, LoadScript } from '@react-google-maps/api';
import PropTypes from 'prop-types';

const libraries = ['places'];

const GooglePlacesAutocomplete = ({ setPosition }) => {
  const searchBoxRef = useRef(null);

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places.length > 0) {
      const place = places[0];
      const location = place.geometry.location;
      setPosition({ lat: location.lat(), lng: location.lng(), name: place.name });
    }
  };

  return (
    <div 
      className="search-box-container"
      style={{
        position: 'absolute',
        top: 10,
        right: 10,
        zIndex: 1000,
        backgroundColor: 'white',
        padding: '2px',
        borderRadius: '4px'
      }}
    >
      <StandaloneSearchBox
        onLoad={(ref) => (searchBoxRef.current = ref)}
        onPlacesChanged={onPlacesChanged}
      >
        <input
          type="text"
          placeholder="Search Google Maps"
          style={{
            padding: '8px',
            width: '300px',
            borderRadius: '4px',
            border: '1px solid #ccc',
          }}
        />
      </StandaloneSearchBox>
    </div>
  );
};

GooglePlacesAutocomplete.propTypes = {
  setPosition: PropTypes.func.isRequired
};

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12, 41],
  popupAnchor: [0, -41]
});
L.Marker.prototype.options.icon = DefaultIcon;

/// This component adds a marker to the map when the user double clicks on the map. Name is automatically generated following WPT1, WPT2,....
const DoubleClickAddMarker = ({ waypoints, setWaypoints }) => {
  let waypointCounter = useRef(1);

  const generateUniqueName = () => {
    const name = `WPT${waypointCounter.current}`;
    waypointCounter.current += 1;
    return name;
  };

  useMapEvent('click', (event) => {
    // Check if the click target is the search box or its children
    const searchBox = document.querySelector('.search-box-container');
    if (event.originalEvent.target.closest('.search-box-container')) {
      return; // Don't add waypoint if click is on search box
    }

    const { lat, lng } = event.latlng;
    const name = generateUniqueName();

    setWaypoints([...waypoints, { lat, lng, name }]);
  });

  return null;
};

DoubleClickAddMarker.propTypes = {
  waypoints: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  setWaypoints: PropTypes.func.isRequired
};

const LeafletMap = ({ position, setPosition, waypoints, setWaypoints }) => {
  const map = useMap();
  const [localPosition, setLocalPosition] = useState(position);

  L.DomUtil.addClass(map._container,'crosshair-cursor-enabled');

  const addWaypoint = (waypoint) => {
    setWaypoints([...waypoints, waypoint]);
  };

  useEffect(() => {
    if (position) {
      map.setView([position.lat, position.lng], 13);
    }
  }, [position, map]);

  return position ? (
    <Marker
      position={[position.lat, position.lng]}
      draggable={true}
      eventHandlers={{
        dragend: (event) => {
          const newPosition = event.target.getLatLng();
          setLocalPosition({ lat: newPosition.lat, lng: newPosition.lng, name: position.name });
        },
        popupclose: (event) => {
          setPosition(null);
        }
      }}
    >
      <Popup>{position.name || 'Selected Location'}
        <br />
        <button
          onClick={() => addWaypoint(localPosition || position)}
          style={{
            padding: '4px 8px',
            border: 'none',
            backgroundColor: '#007bff',
            color: 'white',
            borderRadius: '4px',
            cursor: 'pointer',
            marginTop: '5px',
          }}
        >
          Add to Waypoints
        </button>
      </Popup>
    </Marker>
  ) : null;
};

LeafletMap.propTypes = {
  position: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    name: PropTypes.string
  }),
  setPosition: PropTypes.func.isRequired,
  waypoints: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  setWaypoints: PropTypes.func.isRequired
};

const DraggableWaypoint = ({ index, waypoint, waypoints, setWaypoints }) => {
  const handleDragEnd = (event) => {
    const newLatLng = event.target.getLatLng(); // Get the new position after dragging

    // Create a new array with the updated waypoint
    const updatedWaypoints = waypoints.map((wp, i) => {
      if (i === index) {
        return {
          ...wp,
          lat: newLatLng.lat,
          lng: newLatLng.lng,
        };
      }
      return wp;
    });

    // Update the waypoints state with the new array
    setWaypoints(updatedWaypoints);
  }

  if (waypoint != null) {
    return (
      <Marker
        key={index}
        position={{ lat: waypoint.lat, lng: waypoint.lng }}
        draggable={true}
        eventHandlers={{
          dragend: handleDragEnd,
        }}>
        <Popup>{waypoint.name}</Popup>
      </Marker>
    )
  }
  else {
    return null;
  }
};

DraggableWaypoint.propTypes = {
  index: PropTypes.number.isRequired,
  waypoint: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }),
  waypoints: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  setWaypoints: PropTypes.func.isRequired
};

function DrawRoute({ routes }) {
  const redOptions = { color: 'red' };

  const latlngs = useMemo(() => {
    if (!routes) return [];
    
    return routes.flatMap(route =>
      route.legs.flatMap(leg =>
        leg.polyline.geoJsonLinestring.coordinates.map(coordinate => [
          coordinate[1],
          coordinate[0]
        ])
      )
    );
  }, [routes]);

  if (!routes || latlngs.length === 0) return null;

  return <Polyline pathOptions={redOptions} positions={latlngs} />;
}

DrawRoute.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({
    legs: PropTypes.arrayOf(PropTypes.shape({
      polyline: PropTypes.shape({
        geoJsonLinestring: PropTypes.shape({
          coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired
        }).isRequired
      }).isRequired
    })).isRequired
  }))
};

const MapPage = ({ waypoints, setWaypoints, routes, setRoutes }) => {
  const [position, setPosition] = useState(null);

  return (
    <div className="map-container">
      <MapContainer
        center={{ lat: 38.56667, lng: -7.9 }}
        zoom={6}
        scrollWheelZoom={true}
        doubleClickZoom={false}
        style={{ height: '100%', width: '100%' }}>
        <GooglePlacesAutocomplete setPosition={setPosition} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <DoubleClickAddMarker waypoints={waypoints} setWaypoints={setWaypoints} />

        {waypoints != null ? (
          waypoints.map((waypoint, index) => (
            <DraggableWaypoint key={index} index={index} waypoint={waypoint} waypoints={waypoints} setWaypoints={setWaypoints} />
          ))
        ) : null}
        {routes != null ? (<DrawRoute routes={routes} />) : null}
        <LeafletMap position={position} setPosition={setPosition} waypoints={waypoints} setWaypoints={setWaypoints} />
      </MapContainer>
    </div>
  );
};

MapPage.propTypes = {
  waypoints: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  setWaypoints: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({
    legs: PropTypes.arrayOf(PropTypes.shape({
      polyline: PropTypes.shape({
        geoJsonLinestring: PropTypes.shape({
          coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired
        }).isRequired
      }).isRequired
    })).isRequired
  })),
  setRoutes: PropTypes.func.isRequired
};

export default MapPage;
