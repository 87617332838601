import React from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  Typography, 
  Paper,
  Alert
} from '@mui/material';
import '../../index.css';

const RidersGuidePage = () => {
  return (
    <div className="page-container" style={{ 
      display: 'flex', 
      justifyContent: 'center',
      width: '100%',
      paddingTop: '40px'
    }}>
      <Box sx={{ width: '100%', maxWidth: '600px', mx: 'auto' }}>
        <Paper elevation={3} sx={{ 
          p: 3,
          backgroundColor: '#ffffff',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Typography variant="h4" gutterBottom sx={{ color: '#646464', mb: 2, textAlign: 'center' }}>
            Riders Guide
          </Typography>
          
          <Alert severity="info" sx={{ backgroundColor: '#f5f5f5', color: '#646464' }}>
            We're currently building the Riders Guide section. This will be your comprehensive resource for motorcycle travel tips, 
            safety guidelines, and essential information for planning your adventures. Stay tuned for updates!
          </Alert>
        </Paper>
      </Box>
    </div>
  );
};

RidersGuidePage.propTypes = {
  // Add any props if needed
};

export default RidersGuidePage; 