import React from "react";
import PropTypes from 'prop-types';
import RouteCalculator from "../../RouteCalculator";
import RouteExporter from "../../RouteExporter";
import WaypointList from "../../components/WaypointList"

//Material UI
import Divider from '@mui/material/Divider';

import '../../index.css';

const LeftPage = ({ waypoints, setWaypoints, routes, setRoutes }) => {
    if (!waypoints || !setWaypoints || !setRoutes) {
        return (
            <div className="leftMenu" role="alert">
                <p>Error: Required props are missing. Please check the component configuration.</p>
            </div>
        );
    }

    return (
        <div className="leftMenu" role="navigation" aria-label="Route planning steps">
            <section aria-labelledby="step1-heading">
                <h2 id="step1-heading">Step 1</h2>
                <p>Search your favorite spots on the map or add them directly by clicking on the map.</p>
                <WaypointList waypoints={waypoints} setWaypoints={setWaypoints} routes={routes} setRoutes={setRoutes}/>
            </section>

            <Divider />

            <section aria-labelledby="step2-heading">
                <h2 id="step2-heading">Step 2</h2>
                <RouteCalculator waypoints={waypoints} setWaypoints={setWaypoints} routes={routes} setRoutes={setRoutes} />
            </section>

            <Divider />

            <section aria-labelledby="step3-heading">
                <h2 id="step3-heading">Step 3</h2>
                <p>Export your route.</p>
                <RouteExporter waypoints={waypoints} setWaypoints={setWaypoints} routes={routes} setRoutes={setRoutes} />
            </section>
        </div>
    );
};

LeftPage.propTypes = {
    waypoints: PropTypes.arrayOf(PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    setWaypoints: PropTypes.func.isRequired,
    routes: PropTypes.arrayOf(PropTypes.shape({
        legs: PropTypes.arrayOf(PropTypes.shape({
            polyline: PropTypes.shape({
                geoJsonLinestring: PropTypes.shape({
                    coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired
                }).isRequired
            }).isRequired
        })).isRequired
    })),
    setRoutes: PropTypes.func.isRequired
};

export default LeftPage;