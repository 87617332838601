import React from "react";
import { ListItemIcon, MenuItem, MenuList, Tooltip } from '@mui/material';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Typography from '@mui/material/Typography';
import LoginLogoutButton from "../LoginLogoutButton";
import { useNavigate } from 'react-router-dom';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import '../../index.css';


const TopMenu = () => {
  const navigate = useNavigate();

  return (
    <div className="topmenu">
      <div>
        <img 
          src="/logo2.png" 
          alt="Travel-Riders Logo" 
          style={{ 
            height: '100px',
            marginBottom: '10px'
          }}
        />
      </div>
      <div className="menu-container">
        <MenuList className="horizontal-menu">
          <Tooltip title="Return to the main map page" arrow>
            <MenuItem 
              onClick={() => navigate('/')}
              sx={{ color: '#646464' }}
            >
              <ListItemIcon>
                <HomeIcon className="whiteIcon" />
              </ListItemIcon>
              Home
            </MenuItem>
          </Tooltip>
          <Tooltip title="Explore curated motorcycle adventure packages" arrow>
            <MenuItem 
              onClick={() => navigate('/adventure-packages')}
              sx={{ color: '#646464' }}
            >
              <ListItemIcon>
                <LocalOfferIcon className="whiteIcon" />
              </ListItemIcon>
              Adventure Packages
            </MenuItem>
          </Tooltip>
          <Tooltip title="Read stories and experiences from fellow riders" arrow>
            <MenuItem 
              onClick={() => navigate('/riders-tales')}
              sx={{ color: '#646464' }}
            >
              <ListItemIcon>
                <AutoStoriesIcon className="whiteIcon" />
              </ListItemIcon>
              Rider Tales
            </MenuItem>
          </Tooltip>
          <Tooltip title="Essential tips and guides for motorcycle travelers" arrow>
            <MenuItem 
              onClick={() => navigate('/riders-guide')}
              sx={{ color: '#646464' }}
            >
              <ListItemIcon>
                <MenuBookIcon className="whiteIcon" />
              </ListItemIcon>
              Riders Guide
            </MenuItem>
          </Tooltip>
          <LoginLogoutButton />
        </MenuList>
      </div>
    </div>
  );
};

export default TopMenu;
